import { IndexedValue } from "../types/indexed-value";
import { Pagination } from "../types/pagination";
import {
  Attendant,
  Damage,
  DamageArticleOption,
  DamageReport,
  EventsAndExperienceItem,
  EventsAndExperienceTrip,
  HousekeepingStaff,
  leadCurrentStatusBody,
  MaintenanceStaffType,
  RentalDocument,
  RentalGuest,
  RentalMobileCheck,
  RentalTrip,
  RentalTripItem,
  ValidateCouponItem,
} from "../types/rental-opportunity";
import { IRentalTripGuestService } from "../types/services/rental-trip-guest-service";
import { IRentalTripService } from "../types/services/rental-trip-service";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

class RentalTripService implements IRentalTripService, IRentalTripGuestService {
  async addTrip(
    first_name: string,
    last_name: string,
    email: string,
    country_code: string,
    phone_no: string,
    location: string,
    source_of_enquiry: string,
    message: string,
    brand_id: number,
    source_of_enquiry_other?: string,
    poc_exec?: number,
    poc_head?: number,
    agent?: number,
    home_owner_id?: number,
    customer_id?: number,
  ): Promise<FetchResult<string>> {
    // const [countryCode, phoneNo] = phone_no.split(" ");

    const payload: any = {
      email: email || "",
      country_code: country_code || "",
      mobile: phone_no || "",
      rental_location_id: location,
      source: source_of_enquiry,
      source_others: source_of_enquiry_other || "",
      poc_exec_id: poc_exec,
      message: message || "",
      brand_id: brand_id,
    };

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    payload.name = name;

    if (poc_head) {
      payload.poc_head_id = poc_head;
    }

    if (source_of_enquiry === "agent" && agent !== undefined) {
      payload.agent_id = agent;
    }

    if (home_owner_id !== undefined) {
      payload.home_owner_id = home_owner_id;
    }

    if (customer_id) {
      payload.contact_id = customer_id;
    }

    const url = `/api/v2/rental/opportunities`,
      { response, error } = await POST<TripAddResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    return {
      error: null,
      response: response.slug,
    };
  }

  async getTrips(
    phone_no?: string,
    statuses?: string[],
    stages?: string[],
    sources?: string[],
    enquired_from?: string,
    enquired_until?: string,
    checkin_date?: string,
    checkout_date?: string,
    staff?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalTripItem[]>> {
    const qp = getFilterQp(
      undefined,
      phone_no,
      statuses,
      stages,
      sources,
      enquired_from,
      enquired_until,
      checkin_date,
      checkout_date,
      staff,
      undefined,
      search,
      page,
      page_size,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/rental/opportunities";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<TripsListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.opportunities.map((each) => {
      const {
          name,
          email,
          mobile,
          check_in,
          check_out,
          enquired_at,
          location,
          poc_exec,
          slug,
          status,
          verified,
          repeat_user,
          source,
          country_code,
          referred_by_tier_name,
          properties,
        } = each,
        phoneNo = `${country_code || ""} ${mobile || ""}`.trim(),
        trip: RentalTripItem = {
          slug,
          name: name,
          email: email,
          phone: phoneNo,
          checkin_date: check_in,
          checkout_date: check_out,
          last_modified: enquired_at,
          verified: verified,
          repeat_user: repeat_user,
          poc_executive: poc_exec,
          interested_in: location,
          source,
          status: status || "no_rating",
          refer_by_tier: referred_by_tier_name,
          properties: properties,
        };

      return trip;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  getTripsCSV(
    statuses?: string[],
    stages?: string[],
    sources?: string[],
    enquired_from?: string,
    enquired_until?: string,
    checkin_date?: string,
    checkout_date?: string,
    staff?: string,
  ): string {
    const qp = getFilterQp(
      undefined,
      undefined,
      statuses,
      stages,
      sources,
      enquired_from,
      enquired_until,
      checkin_date,
      checkout_date,
      staff,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/rental/opportunities.csv";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    return url;
  }

  async getTrip(opportunity_slug: string): Promise<FetchResult<RentalTrip>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}`,
      { error, response } = await GET<TripResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        name,
        status,
        check_in,
        check_out,
        current_stage,
        enquired_at,
        location_id,
        poc_exec_id,
        poc_head_id,
        grm_exec_id,
        slug,
        stages,
        message,
        source_city,
        max_budget,
        adults_count,
        children_count,
        source,
        source_others,
        source_mobile,
        source_address,
        contact_id,
        visit_purpose,
        visit_purpose_other,
        agent_id,
        home_owner_id,
        gender,
        properties,
        reservations,
        brand_id,
        villa_attendants,
        original_source,
        is_international,
        ene_poc_id,
        housekeeping_staffs,
        maintenance_staffs,
        utm_meta,
        pre_book_meals,
        pre_book_meals_manual,
        grm_note: { note },
        show_migrate_cta,
        is_migrated,
      } = response.opportunity,
      attendants = (villa_attendants || []).reduce(
        (compiled: Attendant[], each: any) => {
          const { names = [], property_id } = each;

          return [
            ...compiled,
            ...names.map((each: any) => ({
              property_id: property_id,
              attendant_name: each,
            })),
          ];
        },
        [] as Attendant[],
      ),
      housekeeping_attendants = (housekeeping_staffs || []).reduce(
        (compiled: HousekeepingStaff[], each: any) => {
          const { staffs = [], reservation_id } = each;

          return [
            ...compiled,
            ...staffs.map((each: any) => ({
              reservation_id: reservation_id,
              attendant_name: each.name,
              attendant_value: each.value,
            })),
          ];
        },
        [] as HousekeepingStaff[],
      ),
      maintenance_attendants = (maintenance_staffs || []).reduce(
        (compiled: MaintenanceStaffType[], each: any) => {
          const { staffs = [], reservation_id } = each;

          return [
            ...compiled,
            ...staffs.map((each: any) => ({
              reservation_id: reservation_id,
              attendant_name: each.name,
              attendant_value: each.value,
            })),
          ];
        },
        [] as MaintenanceStaffType[],
      ),
      trip: RentalTrip = {
        opportunity_id: id,
        opportunity_slug: slug,
        enquiry_name: name || "",
        status: status || "",
        checkin_date: check_in || "",
        checkout_date: check_out || "",
        current_stage: current_stage || "",
        enquiry_date: enquired_at || "",
        location: location_id || -1,
        poc_executive: poc_exec_id || null,
        poc_head: poc_head_id || null,
        gr_executive: grm_exec_id || null,
        stages: stages || [],
        customer_id: contact_id || -1,
        message: message || "",
        utm_campaign: utm_meta?.utm_campaign || "",
        utm_medium: utm_meta?.utm_medium || "",
        utm_source: utm_meta?.utm_source || "",
        utm_content: utm_meta?.utm_content || "",
        utm_adgroup: utm_meta?.utm_adgroup || "",
        utm_term: utm_meta?.utm_term || "",
        source_city: source_city || "",
        budget: max_budget || "",
        adults: adults_count || "",
        children: children_count || "",
        source_of_enquiry: source || "",
        source_of_enquiry_phone_no: source_mobile || "",
        source_of_enquiry_address: source_address || "",
        source_of_enquiry_other: source_others || "",
        purpose_of_visit: visit_purpose || "",
        purpose_of_visit_other: visit_purpose_other || "",
        gender: gender || "",
        agent: agent_id,
        home_owner_id: home_owner_id,
        properties: properties || [],
        reservations: reservations || [],
        brand_id: brand_id || null,
        attendants: attendants,
        original_source: original_source || null,
        is_international: is_international,
        e_and_e_poc: ene_poc_id || null,
        housekeeping_attendants: housekeeping_attendants,
        maintenance_attendants: maintenance_attendants,
        pre_book_meals: pre_book_meals,
        pre_book_meals_manual: pre_book_meals_manual,
        grm_note: note || "",
        show_migrate_cta: show_migrate_cta || false,
        is_migrated: is_migrated || false,
      };

    return {
      error: null,
      response: trip,
    };
  }

  async updateTrip(
    opportunity_slug: string,
    checkin_date?: string,
    checkout_date?: string,
    location?: number,
    adults?: string,
    children?: string,
    budget?: string,
    source_city?: string,
    purpose_of_visit?: string,
    purpose_of_visit_other?: string,
    message?: string,
    source_of_enquiry?: string,
    source_of_enquiry_phone_no?: string,
    source_of_enquiry_address?: string,
    source_of_enquiry_other?: string,
    agent?: string,
    home_owner_id?: string,
    brand_id?: number,
    pre_book_meals_manual?: boolean,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "check_in",
          value: checkin_date,
        },
        {
          key: "check_out",
          value: checkout_date,
        },
        {
          key: "rental_location_id",
          value: location,
        },
        {
          key: "adults_count",
          value: adults,
        },
        {
          key: "children_count",
          value: children,
        },
        {
          key: "min_budget",
          value: budget,
        },
        {
          key: "max_budget",
          value: budget,
        },
        {
          key: "source_city",
          value: source_city,
        },
        {
          key: "visit_purpose",
          value: purpose_of_visit,
        },
        {
          key: "visit_purpose_other",
          value: purpose_of_visit_other,
        },
        {
          key: "message",
          value: message,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_mobile",
          value: source_of_enquiry_phone_no,
        },
        {
          key: "source_address",
          value: source_of_enquiry_address,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "home_owner_id",
          value: home_owner_id,
        },
        {
          key: "brand_id",
          value: brand_id,
        },
        {
          key: "pre_book_meals_manual",
          value: pre_book_meals_manual,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number | boolean }),
      url = `/api/v2/rental/opportunities/${opportunity_slug}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updatePOC(
    opportunity_slug: string,
    poc_executive?: string,
    poc_head?: string,
    gr_executive?: string,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "poc_exec_id",
          value: poc_executive,
        },
        {
          key: "poc_head_id",
          value: poc_head,
        },
        {
          key: "grm_exec_id",
          value: gr_executive,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number }),
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateAttendants(
    opportunity_slug: string,
    attendants: Attendant[],
    properties: string[],
  ): Promise<FetchResult<void>> {
    const grouped = attendants.reduce((grouped, each) => {
        if (!(each.property_id in grouped)) {
          grouped[each.property_id] = [];
        }

        const group = grouped[each.property_id];

        group.push(each.attendant_name);

        return grouped;
      }, {} as { [k: number]: string[] }),
      payload = {
        villa_attendants: attendants.length
          ? Object.entries(grouped).map(([property_id, attendants]) => ({
              property_id: property_id,
              names: attendants,
            }))
          : properties.map((each) => ({ property_id: each, names: [] })),
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_attendants`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateStages(
    opportunity_slug: string,
    checked: number[],
    vertical?: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        checked: checked,
        vertical: vertical,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/stage`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getGuests(
    opportunity_slug: string,
  ): Promise<FetchResult<RentalGuest[]>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/guests`,
      { error, response } = await GET<GuestResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const guests: RentalGuest[] = response.guests.map((each) => {
      const {
          id,
          name,
          relationship,
          documents,
          dob,
          gender,
          likes,
          dislikes,
          feedback_url,
          primary_email_details,
          primary_mobile_details,
          tag_list,
        } = each,
        nameSplits = (name || "").split(" "),
        guest: RentalGuest = {
          id: id,
          first_name: nameSplits[0] || "",
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          date_of_birth: dob || "",
          gender: gender,
          email: primary_email_details
            ? {
                id: primary_email_details.id,
                value: primary_email_details.email,
              }
            : undefined,
          phone_no: primary_mobile_details
            ? {
                id: primary_mobile_details.id,
                value: `${primary_mobile_details.country_code} ${primary_mobile_details.mobile}`,
              }
            : undefined,
          relationship: relationship
            ? {
                id: relationship.id,
                secondary_contact_id: relationship.relative_contact_id,
                value: relationship.relation,
              }
            : undefined,
          document: documents?.length
            ? {
                id: documents[0].id,
                type: documents[0].document_type,
                value: documents[0].document_identifier,
                file: documents[0].link,
              }
            : undefined,
          likes: likes,
          dislikes: dislikes,
          feedback_url: feedback_url ?? undefined,
          tag_list_Data: tag_list,
        };

      return guest;
    });

    return {
      error: null,
      response: guests,
    };
  }

  async addGuest(
    opportunity_slug: string,
    primary_contact_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    country_code?: string,
    secondary_contact_id?: number,
    relationship_value?: string,
    document_type?: string,
    document_value?: string,
    document_file?: File,
    tag_list_flag?: boolean,
    // likes?: string[],
    // dislikes?: string[],
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    formData.set("name", name);

    if (gender) {
      formData.set("gender", gender);
    }

    if (date_of_birth !== undefined) {
      formData.set("dob", date_of_birth);
    }

    if (email !== undefined) {
      if (email.id !== undefined) {
        formData.set("primary_email_attributes[id]", email.id.toString());
      }

      if (email.value.length > 0) {
        formData.set("primary_email_attributes[email]", email.value);
      }
    }

    if (phone_no !== undefined) {
      if (phone_no.id !== undefined) {
        formData.set("primary_mobile_attributes[id]", phone_no.id.toString());
      }

      if (phone_no.value.length > 0) {
        if (country_code !== undefined) {
          formData.set(
            "primary_mobile_attributes[country_code]",
            country_code.toString(),
          );
        }
        formData.set("primary_mobile_attributes[mobile]", phone_no.value);
      }
    }

    if (primary_contact_id !== undefined) {
      formData.set("contact_id", primary_contact_id.toString());
    }

    if (secondary_contact_id !== undefined) {
      formData.set("relative_contact_id", secondary_contact_id.toString());
    }

    if (relationship_value !== undefined) {
      if (secondary_contact_id !== undefined) {
        formData.set(
          "relationships_attributes[0][relative_contact_id]",
          secondary_contact_id.toString(),
        );
      }
      formData.set("relationships_attributes[0][relation]", relationship_value);
    }

    if (document_type !== undefined && document_file !== undefined) {
      formData.set("documents_attributes[0][document_type]", document_type);
      formData.set("documents_attributes[0][file]", document_file);
      if (document_value !== undefined) {
        formData.set(
          "documents_attributes[0][document_identifier]",
          document_value,
        );
      }
    }

    if (tag_list_flag !== undefined) {
      if (tag_list_flag) {
        formData.set("tag_list[]", "child_below_5");
      }
    }

    // if (likes) {
    //   likes.forEach((each) => formData.append("likes[]", each));
    // }

    // if (dislikes) {
    //   dislikes.forEach((each) => formData.append("dislikes[]", each));
    // }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/guests`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateGuest(
    opportunity_slug: string,
    primary_contact_id: number,
    guest_id: number,
    first_name: string,
    last_name: string,
    gender: string,
    date_of_birth?: string,
    email?: IndexedValue,
    phone_no?: IndexedValue,
    relationship_id?: number,
    secondary_contact_id?: number,
    relationship_value?: string,
    document_id?: number,
    document_type?: string,
    document_value?: string,
    document_file?: File | string,
    tag_list_flag?: boolean,
    // likes?: string[],
    // dislikes?: string[],
  ): Promise<FetchResult<void>> {
    const formData = new FormData();

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    formData.set("name", name);

    if (gender) {
      formData.set("gender", gender);
    }

    if (date_of_birth !== undefined) {
      formData.set("dob", date_of_birth);
    }

    if (email !== undefined) {
      const isStarHiddenValue = email?.value?.includes("*");

      if (email.id !== undefined  && !isStarHiddenValue) {
        formData.set("primary_email_attributes[id]", email.id.toString());
      }
      !isStarHiddenValue && formData.set("primary_email_attributes[email]", email.value);
    }

    if (phone_no !== undefined) {
      const isStarHiddenValue = phone_no?.value?.includes("*");

      if (phone_no.id !== undefined && !isStarHiddenValue) {
        formData.set("primary_mobile_attributes[id]", phone_no.id.toString());
      }

      if (phone_no.value.length > 4 && !isStarHiddenValue) {
        const [countryCode, phoneNo] = phone_no.value.split(" ");

        formData.set("primary_mobile_attributes[country_code]", countryCode);

        formData.set("primary_mobile_attributes[mobile]", phoneNo);
      }
    }

    if (primary_contact_id !== undefined) {
      formData.set("contact_id", primary_contact_id.toString());
    }

    if (
      relationship_value !== undefined &&
      secondary_contact_id !== undefined
    ) {
      if (relationship_id !== undefined) {
        formData.set(
          "relationships_attributes[0][id]",
          relationship_id.toString(),
        );
      }
      formData.set(
        "relationships_attributes[0][relative_contact_id]",
        secondary_contact_id.toString(),
      );
      formData.set("relationships_attributes[0][relation]", relationship_value);
    }

    if (document_type !== undefined) {
      if (document_id !== undefined) {
        formData.set("documents_attributes[0][id]", document_id.toString());
      }
      if (document_value !== undefined) {
        formData.set(
          "documents_attributes[0][document_identifier]",
          document_value,
        );
      }
      formData.set("documents_attributes[0][document_type]", document_type);
      if (document_file instanceof File) {
        formData.set("documents_attributes[0][file]", document_file);
      }

      if (document_id !== undefined && document_file === undefined) {
        formData.set("documents_attributes[0][_destroy]", "true");
      }
    }

    if (tag_list_flag !== undefined) {
      if (tag_list_flag) {
        formData.set("tag_list[]", "child_below_5");
      }
    }

    // if (likes) {
    //   likes.forEach((each) => formData.append("likes[]", each));
    // }

    // if (dislikes) {
    //   dislikes.forEach((each) => formData.append("dislikes[]", each));
    // }

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/guests/${guest_id}`,
      { error } = await PATCH<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteGuest(
    opportunity_slug: string,
    guest_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/guests/${guest_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async resendFeedback(
    opportunity_slug: string,
    guest_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/guests/${guest_id}/resend_feedback`,
      { error } = await POST<void>(url, null);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getTripDocuments(
    opportunity_slug: string,
    document_type?: string,
  ): Promise<FetchResult<RentalDocument[]>> {
    let url = `/api/v2/rental/opportunities/${opportunity_slug}/documents`;
    const qp = new URLSearchParams();

    if (document_type) {
      qp.set("document_type", document_type);
    }
    const marshaled = qp.toString();
    if (marshaled) {
      url = `${url}?${marshaled}`;
    }

    const { error, response } = await GET<TripDocumentsResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted: RentalDocument[] = response.documents.map((each) => {
      const { id, link, document_name, document_identifier, document_type } =
          each,
        formatted: RentalDocument = {
          id: id,
          type: document_type || "",
          name: document_name || document_identifier || "",
          file: link || "",
        };

      return formatted;
    });

    return {
      error: null,
      response: formatted,
    };
  }

  async addTripDocument(
    opportunity_slug: string,
    document_type: string,
    document_name: string,
    file: File,
  ): Promise<FetchResult<void>> {
    const formData = new FormData();
    formData.set("document_type", document_type);
    formData.set("document_name", document_name);
    formData.set("file", file);

    const url = `/api/v2/rental/opportunities/${opportunity_slug}/documents`,
      { error } = await POST<void>(url, formData);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteTripDocument(
    opportunity_slug: string,
    document_id: number,
  ): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/documents/${document_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async getDamageArticleOptions(): Promise<FetchResult<DamageArticleOption[]>> {
    const url = "/api/v2/rental/damages/damage_items";

    const { error, response } = await GET<DamageArticleOptionResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { damage_items } = response,
      formatted = damage_items.map(damageArticleOptionFromAny);

    return {
      error: null,
      response: formatted,
    };
  }

  async getDamageReport(
    opportunity_slug: string,
  ): Promise<FetchResult<DamageReport>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/damages`;

    const { error, response } = await GET<TripDamageReportResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const { damages, total_deposits, net_deposits, security_deposit_refunded } =
        response,
      formatted: DamageReport = {
        refunded: security_deposit_refunded || false,
        deposit_amount: total_deposits || 0,
        refund_amount: net_deposits || 0,
        damages: damages.map((each) => {
          const {
              id,
              property_id,
              property_name,
              name,
              quantity,
              amount,
              report_date,
              dispute,
            } = each || {},
            damageItemName = (name || "")
              .replaceAll("_", " ")
              .replaceAll("-", " - "),
            formatted: Damage = {
              id: id || -1,
              property_id: property_id || "",
              property_name: property_name || "",
              damaged_item: name || "",
              damaged_item_name: damageItemName,
              quantity: quantity || 0,
              amount: amount || 0,
              report_date: report_date || "",
              disputed: dispute || false,
            };

          return formatted;
        }),
      };

    return {
      error: null,
      response: formatted,
    };
  }

  async addDamage(
    opportunity_slug: string,
    property_slug: string,
    damage_article: string,
    report_date: string,
    quantity: number,
    amount: number,
    dispute: boolean,
  ): Promise<FetchResult<void>> {
    const payload = {
        property_id: property_slug,
        name: damage_article,
        date: report_date,
        quantity: quantity,
        amount: amount,
        dispute: dispute,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/damages`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteDamage(damage_id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/rental/damages/${damage_id}`,
      { error } = await DELETE<void>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async freezeDamages(opportunity_slug: string): Promise<FetchResult<void>> {
    const payload = {},
      url = `/api/v2/rental/opportunities/${opportunity_slug}/damages/freeze`,
      { error } = await POST<void>(url, payload);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async refundSecurityDeposit(
    opportunity_slug: string,
  ): Promise<FetchResult<void>> {
    const payload = {},
      url = `/api/v2/rental/opportunities/${opportunity_slug}/damages/security_deposit`,
      { error } = await POST<void>(url, payload);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateHouseKeepingStaff(
    opportunity_slug: string,
    houseKeepingStaff: HousekeepingStaff[],
    properties: string[],
  ): Promise<FetchResult<void>> {
    const grouped = houseKeepingStaff.reduce((grouped, each) => {
        if (!(each.reservation_id in grouped)) {
          grouped[each.reservation_id] = [];
        }

        const group = grouped[each.reservation_id];

        group.push(each.attendant_value);

        return grouped;
      }, {} as { [k: number]: number[] }),
      payload = {
        housekeeping_staffs: houseKeepingStaff.length
          ? Object.entries(grouped).map(
              ([reservation_id, houseKeepingStaff]) => ({
                reservation_id: reservation_id,
                staff_ids: houseKeepingStaff,
              }),
            )
          : properties.map((each) => ({ reservation_id: each, names: [] })),
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_housekeeping_staff`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateMaintenanceStaff(
    opportunity_slug: string,
    maintenanceStaff: MaintenanceStaffType[],
    properties: string[],
  ): Promise<FetchResult<void>> {
    const grouped = maintenanceStaff.reduce((grouped, each) => {
        if (!(each.reservation_id in grouped)) {
          grouped[each.reservation_id] = [];
        }

        const group = grouped[each.reservation_id];

        group.push(each.attendant_value);

        return grouped;
      }, {} as { [k: number]: number[] }),
      payload = {
        maintenance_staffs: maintenanceStaff.length
          ? Object.entries(grouped).map(
              ([reservation_id, maintenanceStaff]) => ({
                reservation_id: reservation_id,
                staff_ids: maintenanceStaff,
              }),
            )
          : properties.map((each) => ({ reservation_id: each, names: [] })),
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_maintenance_staff`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateEandEPOC(
    opportunity_slug: string,
    e_and_e_poc?: string,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "ene_id",
          value: e_and_e_poc,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number }),
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_ene_poc`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async checkPhone(
    phone_no?: string,
    country_code?: string,
    page_size?: string,
  ): Promise<FetchResult<RentalMobileCheck[]>> {
    const qp = new URLSearchParams();

    if (country_code) {
      qp.set("country_code", country_code);
    }

    if (phone_no) {
      qp.set("mobile", phone_no);
    }

    if (page_size) {
      qp.set("per_page", page_size);
    }

    qp.set("show_contact", "true");

    const marshalled = qp.toString();

    let url = "/api/v2/contacts";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<CheckPhoneResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.contacts.map((each) => {
      const {
          id,
          name,
          primary_email_details,
          primary_mobile_details,
          customer_id,
          dob,
        } = each,
        nameSplits = (name || "").split(" "),
        customer: RentalMobileCheck = {
          id,
          first_name: nameSplits[0],
          last_name: nameSplits.length > 1 ? nameSplits.slice(1).join(" ") : "",
          email: primary_email_details,
          phone_no: primary_mobile_details,
          customer_id: customer_id,
          dob: dob,
        };
      return customer;
    });

    return {
      error: null,
      paginate: response.paginate,
      response: formatted,
    };
  }

  async updatePhoneDetails(
    customer_id: number,
    first_name: string,
    last_name: string,
    phone: string,
    phone_id?: number,
    country_code?: string,
    email?: string,
    email_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      name: `${first_name} ${last_name}`,
      primary_email_attributes: {
        id: email_id,
        email: email,
      },
      primary_mobile_attributes: {
        id: phone_id,
        country_code: country_code,
        mobile: phone,
      },
    };

    const url = `/api/v2/contacts/${customer_id}/update_contact`,
      { error, response } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error: error,
        response: undefined,
      };
    }

    return {
      error: null,
      response: response,
    };
  }

  async validateCode(
    opportunity_slug: string,
    reservation_id: string,
    discount_type: string,
    discount_value: string,
    charge_type: string,
    quantity: string,
    base_cost: string,
  ): Promise<FetchResult<ValidateCouponItem>> {
    const payload = {
        rental_reservation_id: reservation_id,
        discount_type: discount_type,
        discount_value: discount_value,
        charge_type: charge_type,
        charge_quantity: quantity,
        base_amount: base_cost,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/validate_discount`,
      { error, response } = await POST<validateCouponResponse>(url, payload);
    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: response.discount_details,
    };
  }

  async updateNoteSection(
    opportunity_slug: string,
    note: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        grm_note: note,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/update_grm_note`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  // Events & experiences

  async addEventAndExpTrips(
    first_name: string,
    last_name: string,
    email: string,
    country_code: string,
    phone_no: string,
    location: string,
    source_of_enquiry: string,
    message: string,
    brand_id: number,
    source_of_enquiry_other?: string,
    ene_poc?: number,
    agent?: number,
    home_owner_id?: number,
    customer_id?: number,
  ): Promise<FetchResult<string>> {
    // const [countryCode, phoneNo] = phone_no.split(" ");

    const payload: any = {
      email: email || "",
      country_code: country_code || "",
      mobile: phone_no || "",
      rental_location_id: location,
      source: source_of_enquiry,
      source_others: source_of_enquiry_other || "",
      ene_poc_id: ene_poc,
      message: message || "",
      brand_id: brand_id,
      vertical: "events_and_experience",
    };

    let name = first_name;

    if (last_name) {
      name = `${name} ${last_name}`;
    }

    payload.name = name;

    if (source_of_enquiry === "agent" && agent !== undefined) {
      payload.agent_id = agent;
    }

    if (home_owner_id !== undefined) {
      payload.home_owner_id = home_owner_id;
    }

    if (customer_id) {
      payload.contact_id = customer_id;
    }

    const url = `/api/v2/rental/opportunities`,
      { response, error } = await POST<TripAddResponse>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    return {
      error: null,
      response: response.slug,
    };
  }

  async getEventAndExpTrips(
    vertical?: string,
    phone_no?: string,
    statuses?: string[],
    stages?: string[],
    location_ids?: string[],
    enquired_from?: string,
    enquired_until?: string,
    checkin_date?: string,
    checkout_date?: string,
    staff?: string,
    search?: string,
    page?: string,
    page_size?: string,
  ): Promise<FetchResult<EventsAndExperienceItem[]>> {
    const qp = getFilterQp(
      vertical,
      phone_no,
      statuses,
      stages,
      undefined,
      enquired_from,
      enquired_until,
      checkin_date,
      checkout_date,
      staff,
      location_ids,
      search,
      page,
      page_size,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/rental/opportunities";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    const { error, response } = await GET<TripsListResponse>(url);

    if (error) {
      return {
        error,
        paginate: undefined,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        paginate: undefined,
        response: null,
      };
    }

    const formatted = response.opportunities.map((each) => {
      const {
          name,
          email,
          mobile,
          check_in,
          check_out,
          enquired_at,
          location,
          ene_poc,
          slug,
          ene_status,
          verified,
          repeat_user,
          source,
          country_code,
          referred_by_tier_name,
          properties,
          lead_current_milestone,
          event_type,
        } = each,
        phoneNo = `${country_code || ""} ${mobile || ""}`.trim(),
        trip: EventsAndExperienceItem = {
          slug,
          name: name,
          email: email,
          phone: phoneNo,
          checkin_date: check_in,
          checkout_date: check_out,
          last_modified: enquired_at,
          verified: verified,
          repeat_user: repeat_user,
          ene_poc: ene_poc,
          interested_in: location,
          source,
          status: ene_status || "no_rating",
          refer_by_tier: referred_by_tier_name,
          properties: properties,
          lead_current_milestone: lead_current_milestone || "",
          event_type: event_type || "",
        };

      return trip;
    });

    return {
      error: null,
      response: formatted,
      paginate: response.paginate,
    };
  }

  getEventsAndExpCSV(
    vertical: string,
    statuses?: string[],
    stages?: string[],
    location_ids?: string[],
    enquired_from?: string,
    enquired_until?: string,
    checkin_date?: string,
    checkout_date?: string,
    staff?: string,
  ): string {
    const qp = getFilterQp(
      vertical,
      undefined,
      statuses,
      stages,
      undefined,
      enquired_from,
      enquired_until,
      checkin_date,
      checkout_date,
      staff,
      location_ids,
    );

    const marshalled = qp.toString();

    let url = "/api/v2/rental/opportunities.csv";

    if (marshalled) {
      url = `${url}?${marshalled}`;
    }

    return url;
  }

  async getEventAndExpDetails(
    opportunity_slug: string,
  ): Promise<FetchResult<EventsAndExperienceTrip>> {
    const url = `/api/v2/rental/opportunities/${opportunity_slug}?vertical=events_and_experience`,
      { error, response } = await GET<TripResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const {
        id,
        name,
        status,
        ene_status,
        check_in,
        check_out,
        current_stage,
        enquired_at,
        location_id,
        poc_exec_id,
        poc_head_id,
        grm_exec_id,
        slug,
        stages,
        message,
        source_city,
        group_size,
        event_type,
        source,
        source_others,
        source_mobile,
        source_address,
        contact_id,
        visit_purpose,
        visit_purpose_other,
        agent_id,
        home_owner_id,
        gender,
        properties,
        reservations,
        brand_id,
        villa_attendants,
        original_source,
        is_international,
        ene_poc_id,
        housekeeping_staffs,
        maintenance_staffs,
        utm_meta,
        pre_book_meals,
        pre_book_meals_manual,
        grm_note: { note },
        milestone_details,
        show_migrate_cta,
        is_migrated,
      } = response.opportunity,
      attendants = (villa_attendants || []).reduce(
        (compiled: Attendant[], each: any) => {
          const { names = [], property_id } = each;

          return [
            ...compiled,
            ...names.map((each: any) => ({
              property_id: property_id,
              attendant_name: each,
            })),
          ];
        },
        [] as Attendant[],
      ),
      housekeeping_attendants = (housekeeping_staffs || []).reduce(
        (compiled: HousekeepingStaff[], each: any) => {
          const { staffs = [], reservation_id } = each;

          return [
            ...compiled,
            ...staffs.map((each: any) => ({
              reservation_id: reservation_id,
              attendant_name: each.name,
              attendant_value: each.value,
            })),
          ];
        },
        [] as HousekeepingStaff[],
      ),
      maintenance_attendants = (maintenance_staffs || []).reduce(
        (compiled: MaintenanceStaffType[], each: any) => {
          const { staffs = [], reservation_id } = each;

          return [
            ...compiled,
            ...staffs.map((each: any) => ({
              reservation_id: reservation_id,
              attendant_name: each.name,
              attendant_value: each.value,
            })),
          ];
        },
        [] as MaintenanceStaffType[],
      ),
      trip: EventsAndExperienceTrip = {
        opportunity_id: id,
        opportunity_slug: slug,
        enquiry_name: name || "",
        status: status || "",
        ene_status: ene_status || "",
        checkin_date: check_in || "",
        checkout_date: check_out || "",
        current_stage: current_stage || "",
        enquiry_date: enquired_at || "",
        location: location_id || -1,
        group_size: group_size || "",
        event_type: event_type || "",
        poc_executive: poc_exec_id || null,
        poc_head: poc_head_id || null,
        gr_executive: grm_exec_id || null,
        stages: stages || [],
        customer_id: contact_id || -1,
        message: message || "",
        utm_campaign: utm_meta?.utm_campaign || "",
        utm_medium: utm_meta?.utm_medium || "",
        utm_source: utm_meta?.utm_source || "",
        utm_content: utm_meta?.utm_content || "",
        utm_adgroup: utm_meta?.utm_adgroup || "",
        utm_term: utm_meta?.utm_term || "",
        source_city: source_city || "",
        source_of_enquiry: source || "",
        source_of_enquiry_phone_no: source_mobile || "",
        source_of_enquiry_address: source_address || "",
        source_of_enquiry_other: source_others || "",
        purpose_of_visit: visit_purpose || "",
        purpose_of_visit_other: visit_purpose_other || "",
        gender: gender || "",
        agent: agent_id,
        home_owner_id: home_owner_id,
        properties: properties || [],
        reservations: reservations || [],
        brand_id: brand_id || null,
        attendants: attendants,
        original_source: original_source || null,
        is_international: is_international,
        e_and_e_poc: ene_poc_id || null,
        housekeeping_attendants: housekeeping_attendants,
        maintenance_attendants: maintenance_attendants,
        pre_book_meals: pre_book_meals,
        pre_book_meals_manual: pre_book_meals_manual,
        grm_note: note || "",
        milestone_details: milestone_details || {},
        show_migrate_cta: show_migrate_cta || false,
        is_migrated: is_migrated || false,
      };

    return {
      error: null,
      response: trip,
    };
  }

  async updateEventsAndExperienceTrip(
    opportunity_slug: string,
    checkin_date?: string,
    checkout_date?: string,
    location?: number,
    group_size?: string,
    event_type?: string,
    purpose_of_visit?: string,
    purpose_of_visit_other?: string,
    message?: string,
    source_of_enquiry?: string,
    source_of_enquiry_phone_no?: string,
    source_of_enquiry_address?: string,
    source_of_enquiry_other?: string,
    agent?: string,
    home_owner_id?: string,
    brand_id?: number,
    pre_book_meals_manual?: boolean,
  ): Promise<FetchResult<void>> {
    const template = [
        {
          key: "check_in",
          value: checkin_date,
        },
        {
          key: "check_out",
          value: checkout_date,
        },
        {
          key: "rental_location_id",
          value: location,
        },
        {
          key: "group_size",
          value: group_size,
        },
        {
          key: "event_type",
          value: event_type,
        },
        {
          key: "visit_purpose",
          value: purpose_of_visit,
        },
        {
          key: "visit_purpose_other",
          value: purpose_of_visit_other,
        },
        {
          key: "message",
          value: message,
        },
        {
          key: "source",
          value: source_of_enquiry,
        },
        {
          key: "source_mobile",
          value: source_of_enquiry_phone_no,
        },
        {
          key: "source_address",
          value: source_of_enquiry_address,
        },
        {
          key: "source_others",
          value: source_of_enquiry_other,
        },
        {
          key: "agent_id",
          value: agent,
        },
        {
          key: "home_owner_id",
          value: home_owner_id,
        },
        {
          key: "brand_id",
          value: brand_id,
        },
        {
          key: "pre_book_meals_manual",
          value: pre_book_meals_manual,
        },
      ],
      payload = template.reduce((compiled, each) => {
        const { key, value } = each;
        if (value !== undefined) {
          compiled[key] = value;
        }
        return compiled;
      }, {} as { [k: string]: string | number | boolean }),
      url = `/api/v2/rental/opportunities/${opportunity_slug}`,
      { error } = await PATCH<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateLeadCurrentStatus(
    opportunity_slug: string,
    leadCurrentStatus: leadCurrentStatusBody[],
  ): Promise<FetchResult<void>> {
    const payload = {
      data: leadCurrentStatus,
    };
    //   payload = template.reduce((compiled, each) => {
    //     const { key, value } = each;
    //     if (value !== undefined) {
    //       compiled[key] = value;
    //     }
    //     return compiled;
    //   }, {} as { [k: string]: string | number }),
    const url = `/api/v2/rental/opportunities/${opportunity_slug}/add_milestones`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  /**
   * Used to Migrate Opportunity
   */
  async migrateVertical(
    opportunity_slug: string,
    from_vertical: string,
    to_vertical: string,
  ): Promise<FetchResult<void>> {
    const payload = {
        from: from_vertical,
        to: to_vertical,
      },
      url = `/api/v2/rental/opportunities/${opportunity_slug}/migrate`,
      { error } = await POST<void>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }
}

export default RentalTripService;

function getFilterQp(
  vertical?: string,
  phone_no?: string,
  statuses?: string[],
  stages?: string[],
  sources?: string[],
  enquired_from?: string,
  enquired_until?: string,
  checkin_date?: string,
  checkout_date?: string,
  staff?: string,
  location_ids?: string[],
  search?: string,
  page?: string,
  page_size?: string,
) {
  const qp = new URLSearchParams();
  if (vertical) {
    qp.set("vertical", vertical);
  }
  if (phone_no) {
    qp.set("mobile", phone_no);
  }
  if (statuses) {
    statuses.forEach((status) => {
      qp.append("status[]", status);
    });
  }
  if (stages) {
    stages.forEach((status) => {
      qp.append("stages[]", status);
    });
  }
  if (sources) {
    sources.forEach((source) => {
      qp.append("sources[]", source);
    });
  }
  if (location_ids) {
    location_ids.forEach((locations) => {
      qp.append("location_ids[]", locations);
    });
  }
  if (search) {
    qp.set("query", search);
  }
  if (enquired_from) {
    qp.set("start_date", enquired_from);
  }
  if (enquired_until) {
    qp.set("end_date", enquired_until);
  }
  if (checkin_date) {
    qp.set("check_in", checkin_date);
  }
  if (checkout_date) {
    qp.set("check_out", checkout_date);
  }
  if (staff) {
    qp.set("staff_id", staff);
  }
  if (page) {
    qp.set("page", page);
  }
  if (page_size) {
    qp.set("per_page", page_size);
  }

  return qp;
}

function damageArticleOptionFromAny(data: any): DamageArticleOption {
  const { name, value, cost, subcategories } = data,
    option: DamageArticleOption = {
      name: name || "",
      value: value || "",
      cost: cost || 0,
      subcategories: subcategories.map(damageArticleOptionFromAny),
    };

  return option;
}

type TripAddResponse = {
  slug: string;
};

type TripsListResponse = {
  opportunities: any[];
  paginate: Pagination;
};

type TripResponse = {
  opportunity: any;
};

type GuestResponse = {
  guests: any[];
};

type TripDocumentsResponse = {
  documents: any[];
};

type TripDamageReportResponse = {
  damages: any[];
  total_deposits: number;
  net_deposits: number;
  security_deposit_refunded: boolean;
};

type DamageArticleOptionResponse = {
  damage_items: any[];
};

type CheckPhoneResponse = {
  contacts: any[];
  paginate: Pagination;
};

type validateCouponResponse = {
  discount_details: ValidateCouponItem;
};
