import {
  MasterOption,
  Masters,
  MastersAgent,
  MastersChapter,
  MastersConcierge,
  MastersDevelopment,
  MastersEventsAndExperience,
  MastersHomeAcquisition,
  MastersRental,
} from "../types/masters";
import { IMastersService } from "../types/services/masters-service";
import createMasterOptionMap from "../utils/create-master-option-map";
import { FetchResult, GET } from "../utils/fetch";

function decorateMasterOptions<T>(response: any): T {
  const formatted: T = Object.entries(response).reduce(
    (compiled, [key, options]) => {
      if (!Array.isArray(options)) {
        return compiled;
      }

      compiled[key] = options;

      compiled[`${key}Map`] = createMasterOptionMap(options as MasterOption[]);

      return compiled;
    },
    {} as any,
  );

  return formatted;
}

class MastersService implements IMastersService {
  async getMasters(): Promise<FetchResult<Masters>> {
    const url = "/api/v2/masters",
      { response, error } = await GET<any>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<Masters>(response);

    /**
     * handling sources which is object of arrays
     */
    formatted.sources = response?.sources;

    return {
      response: formatted,
      error: null,
    };
  }

  async getMastersRental(): Promise<FetchResult<MastersRental>> {
    const url = "/api/v2/masters/rental",
      { response, error } = await GET<any>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersRental>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMastersDevelopment(): Promise<FetchResult<MastersDevelopment>> {
    const url = "/api/v2/masters/development",
      { response, error } = await GET<MastersDevelopment>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersDevelopment>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMastersChapter(): Promise<FetchResult<MastersChapter>> {
    const url = "/api/v2/masters/chapter",
      { response, error } = await GET<MastersChapter>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersChapter>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMasterHA(): Promise<FetchResult<MastersHomeAcquisition>> {
    const url = "/api/v2/masters/home_acqui",
      { response, error } = await GET<MastersHomeAcquisition>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersHomeAcquisition>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMasterAgent(): Promise<FetchResult<MastersAgent>> {
    const url = "/api/v2/masters/agent",
      { response, error } = await GET<any>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersAgent>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMasterConcierge(): Promise<FetchResult<MastersConcierge>> {
    const url = "/api/v2/masters/concierge",
      { response, error } = await GET<any>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted = decorateMasterOptions<MastersConcierge>(response);

    return {
      response: formatted,
      error: null,
    };
  }

  async getMastersEventAndExperience(): Promise<
    FetchResult<MastersEventsAndExperience>
  > {
    const url = "/api/v2/masters/events_and_experience",
      { response, error } = await GET<any>(url);

    if (error) {
      return {
        response: null,
        error,
      };
    }

    if (!response) {
      return {
        response: null,
        error: new Error("Unknown error"),
      };
    }

    const formatted =
      decorateMasterOptions<MastersEventsAndExperience>(response);

    return {
      response: formatted,
      error: null,
    };
  }
}

export default MastersService;
