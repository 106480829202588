import { useEffect } from "react";

import { useMasters } from "../../contexts/masters-context";
import { useMastersService } from "../../contexts/masters-service-context";
import { useSecureState } from "../../contexts/secure-state-context";

function MastersManager() {
  const { isSecure } = useSecureState(),
    {
      getMasters,
      getMastersRental,
      getMastersDevelopment,
      getMastersChapter,
      getMasterHA,
      getMasterConcierge,
      getMasterAgent,
      getMastersEventAndExperience,
    } = useMastersService(),
    {
      masters,
      updateMasters,
      mastersRental,
      updateMastersRental,
      mastersDevelopment,
      updateMastersDevelopment,
      mastersChapter,
      updateMastersChapter,
      mastersHA,
      updateMastersHA,
      mastersAgent,
      updateMastersAgent,
      mastersConcierge,
      updateMastersConcierge,
      mastersEventsAndExperience,
      updateMastersEventsAndExperience,
    } = useMasters(),
    loginRole = sessionStorage.getItem("loginRole"),
    onGetMasters = async () => {
      const ignoreCase = !isSecure || masters.locations.length;

      if (ignoreCase) {
        return;
      }

      const { response, error } = await getMasters();

      if (error || !response) {
        return;
      }

      updateMasters(response);
    },
    onGetMastersRental = async () => {
      const ignoreCase = !isSecure || mastersRental.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { response, error } = await getMastersRental();

      if (error || !response) {
        return;
      }

      updateMastersRental(response);
    },
    onGetMastersDevelopment = async () => {
      const ignoreCase =
        !isSecure || mastersDevelopment.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMastersDevelopment();

      if (error || !response) {
        return;
      }

      updateMastersDevelopment(response);
    },
    onGetMastersChapter = async () => {
      const ignoreCase =
        !isSecure || mastersChapter.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMastersChapter();

      if (error || !response) {
        return;
      }

      updateMastersChapter(response);
    },
    onGetMastersHA = async () => {
      const ignoreCase = !isSecure || mastersHA.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMasterHA();

      if (error || !response) {
        return;
      }

      updateMastersHA(response);
    },
    onGetMastersAgent = async () => {
      const ignoreCase = !isSecure || mastersAgent.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMasterAgent();

      if (error || !response) {
        return;
      }

      updateMastersAgent(response);
    },
    onGetMastersConcierge = async () => {
      const ignoreCase = !isSecure || mastersConcierge.priorities.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMasterConcierge();

      if (error || !response) {
        return;
      }

      updateMastersConcierge(response);
    },
    onGetMastersEventAndExperience = async () => {
      const ignoreCase = !isSecure || mastersEventsAndExperience.opportunity_status.length;

      if (ignoreCase) {
        return;
      }

      const { error, response } = await getMastersEventAndExperience();

      if (error || !response) {
        return;
      }

      updateMastersEventsAndExperience(response);
    };

  useEffect(() => {
    if (loginRole === "agent") {
      onGetMasters();
      onGetMastersRental();
    } else {
      onGetMasters();
      onGetMastersRental();
      onGetMastersDevelopment();
      onGetMastersChapter();
      onGetMastersHA();
      onGetMastersAgent();
      onGetMastersConcierge();
      onGetMastersEventAndExperience();
    }

  }, [isSecure]);

  return null;
}

export default MastersManager;
