import {
  AllActions,
  AppendedReminder,
  ITaskService,
} from "../types/services/task-service";
import { Activity, ClosedReason, Note, Reminder, Task } from "../types/task";
import { DELETE, FetchResult, GET, PATCH, POST } from "../utils/fetch";

type ActivityResponse = {
  activities: {
    task: any;
    reminder: any;
    feed: any;
    note: any;
  }[];
};

class TaskService implements ITaskService {
  async getActions(
    opportunity_slug: string,
    vertical: string,
  ): Promise<FetchResult<AllActions[]>> {
    const qp = new URLSearchParams();
    qp.set("opportunity_slug", opportunity_slug);
    qp.set("vertical", vertical);

    const url = `/api/v2/activities?${qp}`,
      { error, response } = await GET<ActivityResponse>(url);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    const formatted = response.activities.reduce((compiled, each) => {
      const { task, reminder, feed, note } = each;

      if (task) {
        const {
            id,
            assignee,
            assignee_id,
            author,
            author_id,
            created_at,
            deletable,
            editable,
            medium,
            medium_id,
            minutes,
            parent_reminder_id,
            performed_at,
            rating,
            closed_reason,
          } = task,
          formatted: Task = {
            id: id || 0,
            assignee: assignee || "",
            assignee_id: assignee_id || 0,
            author: author || "",
            author_id: author_id || 0,
            created_date: created_at || "",
            deletable: deletable || false,
            editable: editable || false,
            medium: medium || "",
            medium_id: medium_id || "",
            minutes: minutes || "",
            parent_reminder_id: parent_reminder_id || null,
            performed_date: performed_at || "",
            rating: rating || "",
            closed_reason: closed_reason || [],
          };

        return [...compiled, formatted];
      }

      if (reminder) {
        const {
            id,
            agenda,
            assignee,
            assignee_id,
            author,
            author_id,
            created_at,
            deletable,
            done_at,
            due_at,
            editable,
            medium,
            medium_id,
            parent_reminder_id,
            task,
            resolved_by,
          } = reminder,
          formatted: Reminder = {
            id: id || "",
            agenda: agenda || "",
            assignee: assignee || "",
            assignee_id: assignee_id || 0,
            author: author || "",
            author_id: author_id || 0,
            created_date: created_at,
            deletable: deletable || false,
            resolution_date: done_at || "",
            due_date: due_at,
            editable: editable || false,
            medium: medium || "",
            medium_id: medium_id || 0,
            parent_reminder_id: parent_reminder_id || null,
            resolved_by: resolved_by || "",
          };
        if (task) {
          const {
              id,
              assignee,
              assignee_id,
              author,
              author_id,
              created_at,
              deletable,
              editable,
              medium,
              medium_id,
              minutes,
              parent_reminder_id,
              performed_at,
              rating,
              closed_reason,
            } = task,
            formattedTask: Task = {
              id: id || 0,
              assignee: assignee || "",
              assignee_id: assignee_id || 0,
              author: author || "",
              author_id: author_id || 0,
              created_date: created_at || "",
              deletable: deletable || false,
              editable: editable || false,
              medium: medium || "",
              medium_id: medium_id || "",
              minutes: minutes || "",
              parent_reminder_id: parent_reminder_id || null,
              performed_date: performed_at || "",
              rating: rating || "",
              closed_reason: closed_reason || [],
            };
          formatted.task = formattedTask;
        }
        return [...compiled, formatted];
      }

      if (feed) {
        const { id, created_at, description } = feed,
          formatted: Activity = {
            id: id || 0,
            created_date: created_at || "",
            description: description || "",
            performed_date: created_at || "",
          };
        return [...compiled, formatted];
      }

      if (note) {
        const {
            id,
            assignee,
            assignee_id,
            author,
            author_id,
            created_at,
            updated_at,
            deletable,
            editable,
            content,
          } = note,
          formatted: Note = {
            id: id || 0,
            assignee: assignee || "",
            assignee_id: assignee_id || 0,
            author: author || "",
            author_id: author_id || 0,
            created_date: created_at || "",
            updated_date: updated_at || "",
            deletable: deletable || false,
            editable: editable || false,
            note: content || "",
          };

        return [...compiled, formatted];
      }

      return compiled;
    }, [] as AllActions[]);

    return {
      error: null,
      response: formatted,
    };
  }

  async addTask(
    opportunity_slug: string,
    vertical: string,
    minutes: string,
    medium: string,
    rating: string,
    performed_date: string,
    assignee_id: number,
    closed_reason: ClosedReason[],
    parent_reminder_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      opportunity_slug: opportunity_slug,
      minutes: minutes,
      vertical: vertical,
      medium_id: medium,
      rating: rating,
      performed_at: performed_date,
      assignee_id: assignee_id,
      closed_reason: closed_reason || [],
    };
    if (parent_reminder_id !== undefined) {
      payload.parent_task_id = parent_reminder_id;
    }
    const url = `/api/v2/tasks`,
      { error, response } = await POST<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateTask(
    id: number,
    vertical: string,
    minutes?: string,
    medium?: string,
    rating?: string,
    performed_date?: string,
    assignee_id?: number,
    closed_reason?: ClosedReason[],
  ): Promise<FetchResult<void>> {
    const payload: any = {
      minutes: minutes,
      vertical: vertical,
      medium_id: medium,
      rating: rating,
      performed_at: performed_date,
      assignee_id: assignee_id,
      closed_reason: closed_reason,
    };
    const url = `/api/v2/tasks/${id}`,
      { error, response } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteTask(id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/tasks/${id}`,
      { error } = await DELETE<null>(url);
    if (error) {
      return {
        error,
        response: null,
      };
    }
    return {
      error: null,
      response: null,
    };
  }

  async addReminder(
    opportunity_slug: string,
    vertical: string,
    agenda: string,
    medium: string,
    due_date: string,
    assignee_id: number,
    parent_reminder_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      vertical: vertical,
      opportunity_slug: opportunity_slug,
      agenda: agenda,
      medium_id: medium,
      due_at: due_date,
      assignee_id: assignee_id,
    };
    if (parent_reminder_id !== undefined) {
      payload.parent_task_id = parent_reminder_id;
    }
    const url = `/api/v2/reminders`,
      { error, response } = await POST<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateReminder(
    id: number,
    agenda?: string,
    medium?: string,
    due_date?: string,
    assignee_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      agenda: agenda,
      medium_id: medium,
      due_at: due_date,
      assignee_id: assignee_id,
    };
    const url = `/api/v2/reminders/${id}`,
      { error, response } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async resolveReminder(
    id: number,
    minutes: string,
    medium: string,
    rating: string,
    performed_date: string,
    reminders?: AppendedReminder[],
    closed_reason?: ClosedReason[],
    vertical?: string,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      child_task_attributes: {
        minutes,
        medium_id: medium,
        rating,
        performed_at: performed_date,
        closed_reason: closed_reason || [],
      },
      vertical: vertical === "events_and_experience" ? vertical : undefined,
    };
    if (rating !== "closed" && reminders !== undefined) {
      payload.child_reminders_attributes = reminders.map((each) => {
        const { agenda, medium, assignee, due_date } = each;

        return {
          agenda,
          medium_id: medium,
          assignee_id: assignee,
          due_at: due_date,
        };
      });
    }

    const url = `/api/v2/reminders/${id}/resolve`,
      { error, response } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteReminder(id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/reminders/${id}`,
      { error } = await DELETE<null>(url);
    if (error) {
      return {
        error,
        response: null,
      };
    }
    return {
      error: null,
      response: null,
    };
  }

  async addNote(
    opportunity_slug: string,
    vertical: string,
    note: string,
    assignee_id: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
      opportunity_slug: opportunity_slug,
      content: note,
      vertical: vertical,
      assignee_id: assignee_id,
    };
    const url = `/api/v2/notes`,
      { error, response } = await POST<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async updateNote(
    id: number,
    note?: string,
    assignee_id?: number,
  ): Promise<FetchResult<void>> {
    const payload: any = {
        content: note,
        assignee_id: assignee_id,
      },
      url = `/api/v2/notes/${id}`,
      { error, response } = await PATCH<null>(url, payload);

    if (error) {
      return {
        error,
        response: null,
      };
    }

    if (!response) {
      return {
        error: new Error("Unknown error"),
        response: null,
      };
    }

    return {
      error: null,
      response: null,
    };
  }

  async deleteNote(id: number): Promise<FetchResult<void>> {
    const url = `/api/v2/notes/${id}`,
      { error } = await DELETE<null>(url);
    if (error) {
      return {
        error,
        response: null,
      };
    }
    return {
      error: null,
      response: null,
    };
  }
}

export default TaskService;
